import React from 'react';

import logo from '../../assets/img/twospinlogo.png';

const HeaderComponent = ()=>{

    return(
        <header class="header">
			<div class="container-fluid">
				<nav class="navbar navbar-expand-lg header-nav">
					<div class="navbar-header">
						<a id="mobile_btn" href="javascript:void(0);">
							<span class="bar-icon">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>
						<a href="index.html" class="navbar-brand logo">
							<img src={logo} class="img-fluid" alt="Logo" />
						</a>
						<a href="#" class="navbar-brand logo-small">
							<img src={logo} class="img-fluid" alt="Logo" />
						</a>					
					</div>
					<div class="main-menu-wrapper">
						<div class="menu-header">
							<a href="#" class="menu-logo">
								<img src="assets/img/logo.svg" class="img-fluid" alt="Logo" />
							</a>
							<a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
						</div>
						<ul class="main-nav">
							<li class="active"><a href="#">Home</a></li>
							<li class="has-submenu">
								<a href="#">Ev Vehicle <i class="fas fa-chevron-down"></i></a>
								{/* <ul class="submenu">
								    <li><a href="listing-grid.html">Listing Grid</a></li>
								    <li><a href="listing-list.html">Listing List</a></li>					
								    <li><a href="listing-details.html">Listing Details</a></li>								
								</ul> */}
							</li>
							<li class="has-submenu">
								<a href="#">Sale <i class="fas fa-chevron-down"></i></a>
								{/* <ul class="submenu">
								    <li><a href="about-us.html">About Us</a></li>
									<li class="has-submenu">
										<a href="javascript:void(0);">Authentication</a>
										<ul class="submenu">
											<li><a href="register.html">Signup</a></li>
											<li><a href="login.html">Signin</a></li>
											<li><a href="forgot-password.html">Forgot Password</a></li>
											<li><a href="reset-password.html">Reset Password</a></li>
										</ul>
									</li>
									<li class="has-submenu">
										<a href="javascript:void(0);">Used Bike</a>
										<ul class="submenu">
											<li><a href="booking-payment.html">Booking Checkout</a></li>
											<li><a href="booking.html">Booking</a></li>
											<li><a href="invoice-details.html">Invoice Details</a></li>
										</ul>
									</li>
									<li class="has-submenu">
										<a href="javascript:void(0);">Error Page</a>
										<ul class="submenu">
											<li><a href="error-404.html">404 Error</a></li>
											<li><a href="error-500.html">500 Error</a></li>
										</ul>
									</li>
									<li><a href="pricing.html">Pricing</a></li>
									<li><a href="faq.html">FAQ</a></li>
									<li><a href="gallery.html">Gallery</a></li>
									<li><a href="our-team.html">Our Team</a></li>
									<li><a href="testimonial.html">Testimonials</a></li>
									<li><a href="terms-condition.html">Terms & Conditions</a></li>
									<li><a href="privacy-policy.html">Privacy Policy</a></li>			
									<li><a href="maintenance.html">Maintenance</a></li>
									<li><a href="coming-soon.html">Coming Soon</a></li>
								</ul> */}
							</li>
							<li class="has-submenu">
								<a href="#">Used Bike <i class="fas fa-chevron-down"></i></a>
								{/* <ul class="submenu">
								    <li><a href="blog-list.html">Blog List</a></li>
									<li><a href="blog-grid.html">Blog Grid</a></li>
									<li><a href="blog-details.html">Blog Details</a></li>																		
								</ul> */}
							</li>
							<li><a href="contact-us.html">Service Station</a></li>
							<li class="login-link">
								<a href="#">Sign Up</a>
							</li>
							<li class="login-link">
								<a href="#">Sign In</a>
							</li>
						</ul>
					</div>
					<ul class="nav header-navbar-rht">
						<li class="nav-item">
							<a class="nav-link header-login" href="#"><span><i class="fa-regular fa-user"></i></span>Sign In</a>
						</li>
						<li class="nav-item">
							<a class="nav-link header-reg" href="#"><span><i class="fa-solid fa-lock"></i></span>Sign Up</a>
						</li>
					</ul>
				</nav>
			</div>
		</header>
    );

};


export default HeaderComponent;