import React from 'react';


const FooterComponent = ()=>{
    return(
        <div className='container-fluid' style={{backgroundColor:'#000', padding:10,}}>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <p>&copy; 2023 TwoSpins All rights reserved</p>
                </div>
            </div>
        </div>
    );
};


export default FooterComponent;