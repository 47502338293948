import './App.css';
import {useEffect} from 'react';
import {decrement,increment,incrementByAmount,selectCount} from './slice/counterSlice';
import {useDispatch,useSelector} from 'react-redux';
import Header from './components/layouts/HeaderComponent';
import HomeBannerSlider from './components/slider/HomeBannerSlider';
import FooterComponent from './components/layouts/FooterComponent';

function App() {

  const dispatch = useDispatch();
  const count = useSelector(selectCount);

  useEffect(() => {
    const handleMessage = (event) => {
      // if (typeof event.data === 'string') {
      //   const data = JSON.parse(event.data);
      //   alert(data.message);
      //   window.ReactNativeWebView.postMessage(JSON.stringify({ response: 'Message received in WebView!' }));
      // }

      
      if (typeof event.data === 'object') {
        const data = event.data;
        if(data.message){
          alert(data.message);
          window.ReactNativeWebView.postMessage(JSON.stringify({ response: 'Message received in WebView!' }));
        }
      }

    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      {/* <div>
        <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count} fsd</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button>
      </div> */}

      <div className='main-wrapper'>
        <Header />
        <HomeBannerSlider />
        <FooterComponent />
      </div>

    </div>
  );
}

export default App;
