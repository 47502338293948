import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import sliderImage from '../../assets/img/bike-banner.png';


const HomeBannerSlider = ()=>{

    const options = {
        // Customize Owl Carousel options here
        loop: true,
        margin: 10,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };

    return (
      <section className="banner-section banner-slider">
        <div className="container">
          <OwlCarousel className="home-banner" {...options}>
          <div className="row align-items-center">					    
            <div className="col-lg-6" data-aos="fade-down">
                <h1>Explore Limitless Possibilities <br />									
                <span>Unleash Opportunities</span></h1>
                <p>Discover a platform that caters to your needs. Whether you're seeking the latest tech, selling a vehicle, or exploring local services, TwoSpins is your gateway to limitless possibilities.</p>
                {/* <div className="view-all">
                    <a href="listing-grid.html" className="btn btn-view d-inline-flex align-items-center">View all Cars <span><i className="feather-arrow-right ms-2"></i></span></a>
                </div> */}
            </div>
            <div class="col-lg-6" data-aos="fade-down">
                <div className="banner-imgs">
                    <img src={sliderImage} className="img-fluid aos" alt="bannerimage" />							
                </div>
            </div>
        </div>
          </OwlCarousel>
        </div>
      </section>
    );
}

export default HomeBannerSlider;